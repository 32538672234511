import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../Config";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea"; // Para observaciones
import QrReader from "../components/QrReader.tsx";
import { Dropdown } from "primereact/dropdown";
import { obtenerPeriodoActual } from "../utilities/DateUtil.js";
import CapturePhoto from "../components/Camara.js"; // Importar el componente CapturePhoto
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Lectura = (props) => {
  const { baseUrl, headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [lectura, setLectura] = useState("");
  const [estadoMedidor, setEstadoMedidor] = useState(null);
  const [qrDialogVisible, setQrDialogVisible] = useState(false);
  const [manualDialogVisible, setManualDialogVisible] = useState(false);
  const [captureDialogVisible, setCaptureDialogVisible] = useState(false); // Nuevo estado para el diálogo de captura
  const [scannedData, setScannedData] = useState("");
  const [medidor, setMedidor] = useState(null);
  const [periodo, setPeriodo] = useState("");
  const [mostrarLecturaInput, setMostrarLecturaInput] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [observaciones, setObservaciones] = useState(""); // Para guardar las observaciones
  const [mensajeLectura, setMensajeLectura] = useState(""); // Mensaje dinámico para la lectura
  const [capturedImage, setCapturedImage] = useState(null); // Estado para almacenar la imagen capturada
  const [busqueda, setBusqueda] = useState(""); // Para almacenar el término de búsqueda
  const [medidoresEncontrados, setMedidoresEncontrados] = useState([]); // Para almacenar los resultados de la búsqueda

  const dropdownOptions = [
    { label: "OK", value: "OK" },
    { label: "NOK", value: "NOK" },
  ];

  const openQrDialog = () => {
    setQrDialogVisible(true);
  };

  const closeQrDialog = () => {
    setQrDialogVisible(false);
  };

  const openManualDialog = () => {
    setManualDialogVisible(true);
  };

  const closeManualDialog = () => {
    setManualDialogVisible(false);
    setBusqueda("");
    setMedidoresEncontrados([]);
  };

  const openCaptureDialog = () => {
    setCaptureDialogVisible(true); // Mostrar el diálogo de captura de foto
  };

  const closeCaptureDialog = () => {
    setCaptureDialogVisible(false); // Ocultar el diálogo de captura de foto
  };

  const handleScanSuccess = (result) => {
    setScannedData(result);
    closeQrDialog();
  };

  const handleManualInput = () => {
    setScannedData(inputValue);
    closeManualDialog();
  };

  const handleCancelar = () => {
    setInputValue("");
    setLectura("");
    setEstadoMedidor(null);
    setScannedData("");
    setMedidor(null);
    setMostrarLecturaInput(false); // Resetea la visibilidad del InputText
    setMensajeAlerta("");
    setObservaciones(""); // Resetea las observaciones
    setMensajeLectura(""); // Resetea el mensaje de la lectura
    setCapturedImage(null); // Resetea la imagen capturada
    setBusqueda("");
    setMedidoresEncontrados([]);
  };

  const handleSeleccionarMedidor = (medidorSeleccionado) => {
    setMedidor(medidorSeleccionado);
    setManualDialogVisible(false);
    setScannedData(medidorSeleccionado.id);
  };

  const handleBuscarMedidores = async () => {
    if (!busqueda) {
      alert("El término de búsqueda no puede ser vacío");
      return;
    }

    const endpoint = `${baseUrl}getConsultaMedidorManual?busqueda=${busqueda.toUpperCase()}`;

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      });

      const data = await response.json();

      if (!response.ok || !data.respuesta) {
        throw new Error(data.mensaje || "No se encontraron medidores.");
      }

      setMedidoresEncontrados(data.medidores); // Guarda los resultados
    } catch (error) {
      alert("Error en la búsqueda de medidores: " + error.message);
    }
  };
  const handleGuardar = async () => {
    // Validar estadoMedidor
    if (!estadoMedidor) {
      alert("Por favor, seleccione un estado para el medidor.");
      return;
    }

    // Validar observaciones y foto si el estado es NOK
    if (estadoMedidor === "NOK") {
      if (!observaciones || !observaciones.trim()) {
        alert("Por favor, ingrese observaciones para el estado NOK.");
        return;
      }

      if (!capturedImage) {
        alert("Por favor, capture una imagen para el estado NOK.");
        return;
      }
    }

    // Validar medidor y sus propiedades
    if (
      !medidor ||
      medidor.lecturaActual === null || // Check for null
      medidor.lecturaActual === undefined || // Check for undefined
      !medidor.tipo ||
      !medidor.unidadMedida
    ) {
      alert(
        "Los datos del medidor están incompletos. Por favor, revise la información."
      );
      return;
    }

    let consumo = 0;
    let lecturaFinal = lectura ? parseInt(lectura, 10) : null;
    let lecturaInicial = medidor.lecturaActual !== null && medidor.lecturaActual !== undefined
    ? parseInt(medidor.lecturaActual, 10)
    : 0;
  
    // Validar lecturaInicial y lecturaFinal
    if (
      (lecturaFinal === null || isNaN(lecturaFinal)) &&
      estadoMedidor === "OK"
    ) {
      alert("Por favor, ingrese una lectura final válida.");
      return;
    }

    if (
      (lecturaInicial === null || isNaN(lecturaInicial)) &&
      estadoMedidor === "OK"
    ) {
      alert("La lectura inicial del medidor es inválida.");
      return;
    }

    if (estadoMedidor === "OK") {
      // Estado OK: Calculamos el consumo normalmente
      consumo = lecturaFinal - lecturaInicial;
      if (consumo <= 0) {
        alert(
          "La lectura ingresada no puede ser igual o menor que la lectura anterior. Por favor, ingrese un valor válido."
        );
        return;
      }
    } else if (estadoMedidor === "NOK") {
      // Estado NOK: Obtenemos las últimas 6 lecturas y calculamos el promedio
      const endpointLecturas = `${baseUrl}getUltimasLecturas?codigoDomicilio=${encodeURIComponent(
        medidor.domicilioCodigo
      )}`;

      try {
        const response = await fetch(endpointLecturas, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        });
        const data = await response.json();

        if (
          !response.ok ||
          !data.respuesta ||
          !Array.isArray(data.lecturas) ||
          data.lecturas.length === 0
        ) {
          throw new Error(
            data.mensaje || "No se pudieron obtener las lecturas."
          );
        }

        const lecturas = data.lecturas
          .map((l) => parseInt(l, 10))
          .filter((l) => !isNaN(l));
        const sumaLecturas = lecturas.reduce((acc, val) => acc + val, 0);
        consumo = Math.round(sumaLecturas / lecturas.length);

        // Caso NOK, lectura inicial y final son iguales
        lecturaFinal = lecturaInicial;
      } catch (error) {
        alert(
          "Error al obtener lecturas para calcular promedio: " + error.message
        );
        return;
      }
    }

    // Procesar la imagen capturada
    let tipoArchivo = "";
    let archivo = "";
    if (capturedImage) {
      const [fileType, base64Data] = capturedImage.split(",");
      tipoArchivo = fileType ? fileType.split(":")[1] : "";
      archivo = base64Data || "";
    }

    // Validar las propiedades esenciales del medidor
    if (!medidor.id || !medidor.empresa || !medidor.empresa.id) {
      alert("Faltan datos esenciales del medidor o de la empresa.");
      return;
    }

    const payload = {
      idMedidor: medidor.id,
      idEmpresa: medidor.empresa.id,
      codigoDomicilio: medidor.domicilioCodigo || "",
      domicilio: medidor.domicilioDescripcion || "",
      periodoMedicion: periodo || "",
      tipo: medidor.tipo.descripcion || "",
      lecturaInicial: lecturaInicial,
      lecturaFinal: lecturaFinal,
      consumo: consumo,
      unidadMedida: medidor.unidadMedida.descripcion || "",
      estadoMedidor: estadoMedidor || "",
      observacion: observaciones || "",
      tipoArchivo: tipoArchivo,
      archivo: archivo,
    };

    const endpoint = `${baseUrl}guardarDetallePeriodo`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.respuesta) {
        alert("Se ha registrado el medidor correctamente");
        handleCancelar(); // Limpia todas las variables
      } else {
        alert("Error en el guardado: " + data.mensaje);
      }
    } catch (error) {
      alert("Error en el guardado: " + error.message);
    }
  };

  useEffect(() => {
    setPeriodo(obtenerPeriodoActual());
  }, []);

  useEffect(() => {
    if (scannedData) {
      const fetchMedidor = async () => {
        setLoading(true); // Inicia el loading al comenzar el fetch
        const endpoint = `${baseUrl}getConsultaMedidor?id=${scannedData}`;

        try {
          const response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
            headers: headerText,
          });
          const data = await response.json();

          if (!response.ok || data.mensaje !== "OK") {
            throw new Error(data.mensaje || "No se pudo encontrar el medidor.");
          }

          setMedidor(data.medidor); // Guarda el objeto completo si todo va bien
        } catch (error) {
          alert(
            "Error al buscar el medidor: " +
              (error.message || "Ha ocurrido un error inesperado.")
          );
          // Limpia la variable scannedData si hay un error
          setScannedData("");
          setMedidor(null); // Limpia el estado del medidor
        } finally {
          setLoading(false); // Finaliza el loading una vez completado el fetch
        }
      };

      fetchMedidor();
    }
  }, [scannedData, baseUrl, headerText]);

  const handleEstadoChange = (e) => {
    const value = e.value;
    setEstadoMedidor(value);

    if (value === "OK") {
      setMostrarLecturaInput(true);
      setMensajeAlerta("");

      if (medidor && medidor.tipo) {
        if (medidor.tipo.descripcion === "AGUA") {
          setMensajeLectura("4 dígitos de la izquierda del remarcador");
          setLectura((prevLectura) => (prevLectura || "").substring(0, 4)); // Limitar a 4 dígitos
        } else if (medidor.tipo.descripcion === "ELECTRICIDAD") {
          setMensajeLectura("6 dígitos de la izquierda del remarcador");
          setLectura((prevLectura) => (prevLectura || "").substring(0, 6)); // Limitar a 6 dígitos
        }
      }
    } else if (value === "NOK") {
      setMostrarLecturaInput(false);
      setMensajeAlerta("Este mes se registrará el consumo promedio.");
    }
  };

  const handleLecturaChange = (e) => {
    let value = e.target.value;

    if (medidor && medidor.tipo) {
      if (medidor.tipo.descripcion === "AGUA") {
        value = value.slice(0, 4); // Limitar a 4 dígitos
      } else if (medidor.tipo.descripcion === "ELECTRICIDAD") {
        value = value.slice(0, 6); // Limitar a 6 dígitos
      }
    }

    setLectura(value);
  };

  const handleCapture = (imageSrc) => {
    setCapturedImage(imageSrc);
  };

  return (
    <div className="col-12 md:col-12">
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ margin: 0, color: "#495057" }}>Lectura de medidores</h4>
          <h5 style={{ margin: 0, color: "#6c757d" }}>Periodo {periodo}</h5>
        </div>
        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Seleccione un método de registro:
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginBottom: "20px",
            }}
          >
            <Button
              type="button"
              onClick={openQrDialog}
              label="Escanear Código QR"
              icon="pi pi-qrcode"
              className="p-button"
              style={{
                width: "250px",
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "14px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Button
              type="button"
              onClick={openManualDialog}
              label="Ingreso Manual"
              icon="pi pi-book"
              className="p-button-success"
              style={{
                width: "250px",
                backgroundColor: "#28a745",
                borderColor: "#28a745",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "14px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </div>
        </div>
      </div>
      {!loading && scannedData && (
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Remarcador encontrado:
          </h5>
          <div className="grid" style={{ marginBottom: "20px" }}>
            <div className="col-6" style={{ paddingRight: "10px" }}>
              <p>
                <strong>Tipo:</strong> {medidor?.tipo?.descripcion || "N/A"}
              </p>
              <p>
                <strong>Unidad de Medida:</strong>{" "}
                {medidor?.unidadMedida?.descripcion || "N/A"}
              </p>
              <p>
                <strong>Marca:</strong> {medidor?.marca}
              </p>
              <p>
                <strong>Empresa:</strong> {medidor?.empresa?.nombre || "N/A"}
              </p>
            </div>
            <div className="col-6" style={{ paddingLeft: "10px" }}>
              <p>
                <strong>Código Domicilio:</strong>{" "}
                {medidor?.domicilioCodigo || "N/A"}
              </p>
              <p>
                <strong>Descripción Domicilio:</strong>{" "}
                {medidor?.domicilioDescripcion || "N/A"}
              </p>
              <p>
                <strong>Dirección Domicilio:</strong>{" "}
                {medidor?.domicilioDireccion || "N/A"}
              </p>
              <p>
                <strong>Lectura Anterior:</strong>{" "}
                {medidor?.lecturaActual || "N/A"}
              </p>
            </div>
          </div>

          <h5
            style={{
              borderBottom: "2px solid #ced4da",
              paddingBottom: "10px",
              marginBottom: "20px",
              color: "#495057",
            }}
          >
            Ingrese la medición:
          </h5>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="estado"
              style={{
                fontWeight: "bold",
                marginBottom: "5px",
                display: "block",
              }}
            >
              Estado Medidor:
            </label>
            <Dropdown
              id="estado"
              value={estadoMedidor}
              options={dropdownOptions}
              onChange={handleEstadoChange}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
                marginBottom: "15px",
              }}
              placeholder="Seleccione un estado"
            />

            {estadoMedidor && (
              <>
                {mostrarLecturaInput && (
                  <>
                    <label
                      htmlFor="numero"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Lectura Actual:
                    </label>
                    <InputText
                      id="numero"
                      value={lectura}
                      onChange={handleLecturaChange}
                      placeholder="Ingrese lectura"
                      maxLength={medidor?.tipo?.descripcion === "AGUA" ? 4 : 6}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ced4da",
                        marginBottom: "15px",
                      }}
                    />
                    {mensajeLectura && (
                      <p
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginTop: "5px",
                        }}
                      >
                        {mensajeLectura}
                      </p>
                    )}
                  </>
                )}
                {mensajeAlerta && (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginTop: "5px",
                    }}
                  >
                    {mensajeAlerta}
                  </p>
                )}

                <label
                  htmlFor="observaciones"
                  style={{
                    fontWeight: "bold",
                    marginBottom: "5px",
                    display: "block",
                  }}
                >
                  Observaciones:
                </label>
                <InputTextarea
                  id="observaciones"
                  value={observaciones}
                  onChange={(e) => setObservaciones(e.target.value)}
                  placeholder="Ingrese observaciones"
                  rows={3}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ced4da",
                    marginBottom: "15px",
                  }}
                />
                <Button
                  label="Adjuntar Imagen"
                  icon="pi pi-image"
                  className="p-button-secondary"
                  style={{
                    backgroundColor: "#ffc107",
                    color: "#fff",
                    padding: "10px 20px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    marginBottom: "20px",
                    display: "block",
                  }}
                  onClick={openCaptureDialog}
                />
                {capturedImage && (
                  <div>
                    <h5>Foto Capturada:</h5>
                    <img
                      src={capturedImage}
                      alt="Foto Capturada"
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              label="Guardar"
              icon="pi pi-save"
              className="p-button-success"
              style={{
                backgroundColor: "#28a745",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "14px",
                borderRadius: "4px",
                flex: 1,
                marginRight: "10px",
              }}
              onClick={handleGuardar}
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              className="p-button-danger"
              style={{
                backgroundColor: "#dc3545",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "14px",
                borderRadius: "4px",
                flex: 1,
                marginLeft: "10px",
              }}
              onClick={handleCancelar}
            />
          </div>
        </div>
      )}

      {loading && scannedData && (
        <div>
          <p>Cargando información del medidor...</p>
        </div>
      )}

      <Dialog
        header="Escanear Código QR"
        visible={qrDialogVisible}
        style={{ width: "100vw" }}
        onHide={closeQrDialog}
      >
        <QrReader onScanSuccess={handleScanSuccess} />
      </Dialog>

      <Dialog
        header="Ingreso Manual"
        visible={manualDialogVisible}
        style={{ width: "75vw" }}
        onHide={closeManualDialog}
      >
        <div className="col-12 md:col-12">
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div style={{ flex: 1 }}>
              <label
                htmlFor="manualInput"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  display: "block",
                  marginBottom: "5px",
                }}
              >
                Número remarcador o descripción:
              </label>
              <InputText
                id="manualInput"
                value={busqueda}
                placeholder="Ej: 00123456 o BODEGA"
                onChange={(e) => setBusqueda(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "14px",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.2)",
                  minWidth: "200px",
                }}
                autoComplete={false}
              />
              <small
                style={{ display: "block", marginTop: "5px", color: "#6c757d" }}
              >
                Ingrese el término de búsqueda para buscar medidores.
              </small>
            </div>
            <div
              style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}
            >
              <Button
                label="Buscar"
                icon="pi pi-search"
                onClick={handleBuscarMedidores}
                className="p-button-success"
                style={{
                  padding: "10px 20px",
                  fontSize: "14px",
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                  borderRadius: "4px",
                  color: "#fff",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Button
                label="Cancelar"
                onClick={closeManualDialog}
                icon="pi pi-times"
                className="p-button-danger"
                style={{
                  padding: "10px 20px",
                  fontSize: "14px",
                  backgroundColor: "#dc3545",
                  borderColor: "#dc3545",
                  borderRadius: "4px",
                  color: "#fff",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </div>
          </div>

          {medidoresEncontrados.length > 0 && (
            <DataTable value={medidoresEncontrados} className="p-mt-4 ml-3">
              <Column sortable field="domicilioDescripcion" header="Descripción" />
              <Column sortable field="domicilioDireccion" header="Dirección" />
              <Column sortable field="tipo.descripcion" header="Tipo de Medidor" />
              <Column
                header="Acción"
                body={(rowData) => (
                  <Button
                    label="Seleccionar"
                    onClick={() => handleSeleccionarMedidor(rowData)}
                    className="p-button-success"
                    icon="pi pi-check"
                    style={{
                      padding: "10px 20px",
                      fontSize: "14px",
                      backgroundColor: "#28a745",
                      borderColor: "#28a745",
                      borderRadius: "4px",
                      color: "#fff",
                      cursor: "pointer",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                )}
              />
            </DataTable>
          )}
        </div>
      </Dialog>

      <Dialog
        header="Captura de Foto"
        visible={captureDialogVisible}
        style={{ width: "100vw" }}
        onHide={closeCaptureDialog}
      >
        <CapturePhoto onClose={closeCaptureDialog} onCapture={handleCapture} />
      </Dialog>
    </div>
  );
};

export default Lectura;
